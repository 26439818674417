import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Text } from 'rebass/styled-components'
import { navigate, Link } from 'gatsby'

import Table from '../../components/tables/Table'
import TableCheckBox from '../../components/tables/TableCheckBox'
// import TableSort from '../../components/tables/TableSort'
import { sortTable } from '../../system/redux/reducers/tables'
import Cell from '../../components/tables/Cell'
import Row from '../../components/tables/Row'
import SaveIcon from '../../components/general/SaveIcon'
import EllipsisControls from '../../components/tables/EllipsisControls'
import EllipsisControlsItems from '../../components/tables/EllipsisControlsItems'
import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import { getUsers } from '../../models/Users'

import { onSnapshot, collection, orderBy, query, Timestamp } from 'firebase/firestore'
import { db } from '../../system/firebase/index'

const UsersView = ({ dispatch, ID, order }) => {
    const [itemList, updateList] = useState([])
    const [allChecked, checkAll] = useState(false)
    const sortDirection = order[ID] ? order[ID].direction : false
    const sortColumn = order[ID] ? order[ID].col : false
    const template = '0.7fr 1.5fr 2.2fr 1fr 1fr 0.3fr'
    const [list, setList] = useState(false)

    useEffect(() => {
        let isCancelled = false
        const q = query(collection(db, 'users'))
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const docData = doc.data()
                    docData.id = doc.id
                    output.push(docData)
                })
                setList(output)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    const areYouSure = (id) => {
        const confirmData = JSON.stringify({ isOpen: true, id, modalType: 'DELETE_USER', collection: null })
        dispatch(confirmBoxToggle(confirmData))
    }
    const goToEdit = (id) => {
        navigate(`/users/edit/${id}`)
    }

    const addNew = () => {
        navigate(`/users/add/`)
    }

    useEffect(() => {
        const itemRow = []
        let count = 0
        if (list) {

            Object.entries(list).map(async ([key, user]) => {
                itemRow.push(
                    <Row key={user.id} py='sm' template={template}>
                        <Cell className="center">
                            {/* <TableCheckBox checked={allChecked} doc={user.id} table={ID} /> */}
                        </Cell>
                        <Cell fontWeight="bold">
                            <Link to={`/users/edit/${user.id}`}>
                                { user.first_name + ' ' + user.last_name }
                            </Link>
                        </Cell>
                        <Cell>
                            {user.email}
                        </Cell>
                        <Cell>
                            {user.roles.superadmin && user.roles.admin && user.roles.editor && user.roles.viewer ? 'Superadmin' : null}
                            {!user.roles.superadmin && user.roles.admin && user.roles.editor && user.roles.viewer ? 'Admin' : null}
                            {!user.roles.superadmin && !user.roles.admin && user.roles.editor && user.roles.viewer ? 'Editor' : null}
                            {!user.roles.superadmin && !user.roles.admin && !user.roles.editor && user.roles.viewer ? 'Viewer' : null}
                        </Cell>
                        <Cell>
                            {user.specialist ? 'Yes' : 'No'}
                        </Cell>
                        <Cell className="end">
                            <EllipsisControls>
                                <EllipsisControlsItems onClick={() => goToEdit(user.id)}>Edit</EllipsisControlsItems>
                                <EllipsisControlsItems onClick={() => areYouSure(user.id)}>Delete</EllipsisControlsItems>
                            </EllipsisControls>
                        </Cell>
                    </Row>,
                )
                count++
                if (list.length === count) {
                    // We have iterated through all rows now and gathered the data
                    updateList(itemRow)
                }

            })
        }
    }, [list, allChecked]);

    const headerRow = [
        <Cell className="center" key="checkbox" justifyContent="center">
            {/* <TableCheckBox onPress={() => checkAll(!allChecked)} /> */}
        </Cell>,
        // <Cell key="title" onClick={ () => sortByColumn('title') }><Text>Title</Text><TableSort direction={ ( sortColumn === 'title' ) ? sortDirection : false } /></Cell>,
        <Cell key="name"><Text>Name</Text></Cell>,
        <Cell key="email"><Text>Email</Text></Cell>,
        <Cell key="type"><Text>Type</Text></Cell>,
        <Cell key="specialist"><Text>Specialist</Text></Cell>,
        <Cell key="actions"></Cell>,
    ]

    return (
        <Table title="All Users" ID={ID} contentType="POST" addNew={addNew} template={template} headerRow={headerRow}>
            { itemList.length ? itemList : null }
        </Table>
    )
}

function mapStateToProps(state) {
    return {
        order: state.Tables.sort,
        ID: state.ID || 'TABLE_USERS',
    }
}

export default connect(mapStateToProps)(UsersView)
