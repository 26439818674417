import React, { useEffect, useState } from 'react'
import { Flex, Text } from 'rebass/styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { AnimatePresence, motion } from 'framer-motion'

const SaveIcon = ({ onPress, visible }) => {
    const [isVisible, setState] = useState(false)
    useEffect(() => {
        setState(visible)
    }, [visible]);
    return (
        <AnimatePresence>
            {isVisible &&
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: 'easeOut', duration: .3 }}>
                    <Flex alignItems="center" justifyContent="center" flexDirection="column" height="100%" pl={2} onClick={onPress} sx={{
                        cursor: 'pointer',
                        ':hover': {
                            color: 'red',
                        },
                    }}>
                        <FontAwesomeIcon icon={ faSave } />
                        <Text fontSize="xs">Save</Text>
                    </Flex>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default SaveIcon;
