import React, { useState, useEffect } from 'react'
import { Box, Button } from 'rebass/styled-components'
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../../system/firebase/index'
import { Label, Input } from '@rebass/forms/styled-components'
import { connect } from 'react-redux'
import { updateUser, updateAuth } from '../../models/Users'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import Loading from '../../components/general/Loading'


const EditView = ({ dispatch, id }) => {
    const [saving, setSaving] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [saved, setSaved] = useState({ color: 'greyMedium', pointerEvents: 'none', text: 'Update User', init: false })
    useEffect(() => {
        if (isSaved) {
            setSaved({ color: 'green', pointerEvents: 'none', text: 'User Updated', init: true })
        } else {
            setSaved({ color: 'red', pointerEvents: 'auto', text: 'Update User', init: false })
        }
    }, [isSaved])
    const { register, reset, handleSubmit, formState: { errors }} = useForm()
    const [details, setDetails] = useState({})
    const [role, setRole] = useState({ value: '', label: '' })
    const [specialist, setSpecialist] = useState()
    const [newEmail, setNewEmail] = useState('')
    const [newPassword, setPassword] = useState('')
    useEffect(() => {
        let isCancelled = false
        const userRef = collection(db, 'users');
        const q = query(userRef, where('uid', '==', id));
        onSnapshot(q, (snapshot) => {
            if (!isCancelled) {
                const output = []
                snapshot.forEach((doc) => {
                    const data = doc.data()
                    output.push(data)
                })
                // Todo: Relook at this so that we don't need to remove the password like this
                delete output[0].password // remove the password from being passed in.
                setDetails(output[0])
            }
        })
        return () => {
            isCancelled = true;
        }
    }, [id])

    useEffect(() => {
        if (details && details.specialist === true) {
            setSpecialist({ value: true, label: 'True' })
        } else {
            setSpecialist({ value: false, label: 'False' }
            )}
        if(details.roles && details.roles.superadmin) {
            setRole({ value: 'superadmin', label: 'Superadmin' })
    
        } else if (details.roles && details.roles.admin){
            setRole({ value: 'admin', label: 'Admin' })
            return
        } else if (details.roles && details.roles.editor) {
            setRole({ value: 'editor', label: 'Editor' })
            return
        } else if (details && details.roles) {
            setRole({ value: 'viewer', label: 'Viewer' })
            return
        }
        reset({ details }, { keepErrors: true })
    },[details, reset])

    const submitFunction = (e) => {
        setSaving(true)
        if (newEmail || newPassword) {
            const data = {}
            data.email = newEmail || details.email
            if (newPassword) data.password = newPassword
            updateAuth(id, data)
        }
        delete details.password // We don't want the plain password to find its way into the DB
        updateUser(id, details).then(() => {
            setSaving(false)
            setIsSaved(true)
            setTimeout(() => {
                setIsSaved(false)
            }, 1500)
        })
    }

    const handleChange = (e, type) => {
        setIsSaved(false)
        if (type === 'email') {
            setNewEmail(e.target.value)
        }
        if (type === 'password') {
            setPassword(e.target.value)
        }
        setDetails({ ...details, [type]: e.target.value })
    }

    const handleSelectChange = (e, type) => {
        setIsSaved(false)
        type === 'role' ? setRole(e) : setSpecialist(e)
        setDetails({ ...details, [type]: e.value })
        if (type === 'role' && e.value === 'superadmin'){
            setDetails({ ...details, roles: {
                superadmin: true,
                admin: true,
                editor: true,
                viewer: true
            }})
        } else if (type ==='role' && e.value === 'admin'){
            setDetails({ ...details, roles: {
                superadmin: false,
                admin: true,
                editor: true,
                viewer: true
            }})
        } else if (type ==='role' && e.value === 'editor'){
            setDetails({ ...details, roles: {
                superadmin: false,
                admin: false,
                editor: true,
                viewer: true
            }})
        } else if (type ==='role' && e.value === 'viewer'){
            setDetails({ ...details, roles: {
                superadmin: false,
                admin: false,
                editor: false,
                viewer: true
            }})
        }
    }

    const roles = [
        { 
            value: 'superadmin', 
            label: 'Super Admin'
        },{ 
            value: 'admin',
            label: 'Admin'
        },{
            value: 'editor',
            label: 'Editor'
        },{
            value: 'viewer',
            label: 'Viewer'
        }
    ];
    const specialists = [
        { 
            value: true, 
            label: 'True'
        },{ 
            value: false,
            label: 'False'
        }
    ];
    return (
        <Box as='form' onSubmit={handleSubmit(() => {submitFunction() })}>
            <Input
                {...register(
                    'email',
                    { required: "Cannot be empty", pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }})}
                autoComplete='off'
                onChange={(e) => handleChange(e, 'email')}
                defaultValue={details.email || null} type='text'
                placeholder='New Email'
                mb={errors && errors.email ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}
                autocomplete="off"
            />
            {errors && errors.email && 
            <Box fontSize='md' color='red'>{errors.email.message}</Box>
            }
            <Input
                {...register('password', 
                    { 
                        // required: "Cannot be empty", // modification can have password emtpy - will stay as-is
                        pattern: {
                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: "New password must be at least 8 characters long, and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol"
                        }
                    }
                )}
                autoComplete='off'
                onChange={(e) => handleChange(e, 'password')}
                defaultValue={details.password || null}
                type='password'
                placeholder='New Password'
                mt={errors && errors.email ? 'xxs' : 'sm'} 
                mb={errors && errors.password ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}
                autocomplete="off"
            />
            {errors && errors.password && 
            <Box fontSize='md'color='red'>{errors.password.message}</Box>
            }
            <Input
                {...register('first_name', { required: "Cannot be empty" })}
                onChange={(e) => handleChange(e, 'first_name')}
                defaultValue={details.first_name || null}
                type='text'
                placeholder='First Name'
                mt={errors && errors.password ? 'xxs' : 'sm'} 
                mb={errors && errors.first_name ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.first_name && 
            <Box fontSize='md'color='red'>{errors.first_name.message}</Box>
            }
            <Input
                {...register('last_name', { required: "Cannot be empty" })}
                onChange={(e) => handleChange(e, 'last_name')}
                defaultValue={details.last_name || null}
                type='text'
                placeholder='Last Name'
                mt={errors && errors.first_name ? 'xxs' : 'sm'} 
                mb={errors && errors.last_name ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.last_name && 
                <Box fontSize='md'color='red'>{errors.last_name.message}</Box>
            }
            <Label htmlFor='roles'>Role</Label>
            {details && details.roles && 
            <Select
                id='roles'
                name='roles'
                value={role}
                onChange={(e) => handleSelectChange(e, 'role')}
                options={roles} />
            }
            <br/>
            <Label htmlFor='specialists'>Specialist</Label>
            {details && details.roles &&
            <Select
                id='specialists'
                name='specialists'
                value={specialist}
                onChange={(e) => handleSelectChange(e, 'specialist')}
                options={specialists} />
            }

            {
                saving ?
                    <Box mt='md' width='15px'>
                        <Loading size='md' />
                    </Box> :
                    <Button mt='md' bg={saved.color} sx={{
                        borderColor: saved.color,
                        pointerEvents: saved.pointerEvents }}>{saved.text}</Button>
            }
        </Box>
    );
};

function mapStateToProps(state) {
    return {
        errors: state.User.errors,
        message: state.User.message,
    };
}

export default connect(mapStateToProps)(EditView)