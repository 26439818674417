import React from 'react'
import Layout from './templates/adminLayout'

const Pointer = ({ location, PageInfo, Component, minimumRole, id, startingTab = false, ...rest }) => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={minimumRole} {...rest}>
            <Component location={location} PageInfo={PageInfo} id={id} category={rest.category || ''} startingTab={startingTab} />
        </Layout>
    )
}

export default Pointer
