import React, { useState, useEffect } from 'react'
import { Box, Button } from 'rebass/styled-components'
import { Label, Input } from '@rebass/forms/styled-components'
import { connect } from 'react-redux'
import { registerUser } from '../../models/Users'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { onSnapshot, collection, query, where } from 'firebase/firestore'
import { db } from '../../system/firebase/index'


const EditView = ({ dispatch }) => {
    const [isSaved, setIsSaved] = useState(false)
    const [isNotSaved, setIsNotSaved] = useState()
    const [saved, setSaved] = useState({ color: 'greyMedium', pointerEvents: 'none', text: 'Save', init: false })
    useEffect(() => {
        if (isSaved) {
            setSaved({ color: 'green', pointerEvents: 'none', text: 'Saved', init: true })
        }
        if (isNotSaved) {
            setSaved({ color: 'red', pointerEvents: 'auto', text: 'Save', init: false })
        }
    }, [isNotSaved, isSaved])

    const { register, handleSubmit, reset, setError, formState: { errors }} = useForm()
    const [details, setDetails] = useState({
        role: { value: 'editor', label: 'Editor' },
        specialist: false
    })

    const submitFunction = (e) => {
        const q = query(collection(db, 'users'), where('email', '==', details.email));
        onSnapshot( q, (snapshot) => {
            if (snapshot.docs.length === 0){
                registerUser(dispatch, details)
                setIsSaved(true)
                setIsNotSaved(false)
                reset({
                    email: '',
                    password: '',
                    first_name: '',
                    last_name: '',
                }, {
                    keepErrors: true,
                    keepDirty: false,
                    keepIsSubmitted: true,
                    keepTouched: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                })
            }
            else if (!details.email === '') {
                setError('email', { type: 'custom', message: 'Email address already in use' }, { type: 'focus', shouldFocus: true })
            }
        })
    }
    const handleChange = (e, type) => {
        setIsNotSaved(true)
        setIsSaved(false)
        setDetails({ ...details, [type]: e.target.value })
    }
    const handleSelectChange = (e, type) => {
        setIsNotSaved(true)
        setIsSaved(false)
        setDetails({ ...details, [type]: e.value })
    }

    const roles = [
        { 
            value: 'superadmin', 
            label: 'Super Admin'
        },{ 
            value: 'admin',
            label: 'Admin'
        },{
            value: 'editor',
            label: 'Editor'
        },{
            value: 'viewer',
            label: 'Viewer'
        }
    ]
    const specialists = [
        { 
            value: true, 
            label: 'True'
        },{ 
            value: false,
            label: 'False'
        }
    ]

    return (
        <Box as='form' onSubmit={handleSubmit(() => {submitFunction() })}>
            <Input 
                {...register(
                    'email',
                    { required: "Cannot be empty", pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address"
                    }})}
                autoComplete='off'
                onChange={(e) => handleChange(e, 'email')} 
                type='text'
                name='email'
                placeholder='Email' 
                mb={errors && errors.email ? 'xxs' : 'sm'} 
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.email && 
                <Box fontSize='md'color='red'>{errors.email.message}</Box>
            }
            <Input 
                {...register('password', { required: "Cannot be empty", pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: "Password must be at least 8 characters long, and contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol"
                }})}
                autoComplete='off'
                onChange={(e) => handleChange(e, 'password')}
                type='password'
                placeholder='Password' 
                mt={errors && errors.email ? 'xxs' : 'sm'} 
                mb={errors && errors.password ? 'xxs' : 'sm'} 
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.password && 
                <Box fontSize='md'color='red'>{errors.password.message}</Box>
            }
            <Input 
                {...register('first_name', { required: "Cannot be empty" })}
                onChange={(e) => handleChange(e, 'first_name')}
                type='text'
                placeholder='First Name' 
                mt={errors && errors.password ? 'xxs' : 'sm'} 
                mb={errors && errors.first_name ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.first_name && 
                <Box fontSize='md'color='red'>{errors.first_name.message}</Box>
            }
            <Input 
                {...register('last_name', { required: "Cannot be empty" })}
                onChange={(e) => handleChange(e, 'last_name')}
                type='text'
                placeholder='Last Name'
                mt={errors && errors.first_name ? 'xxs' : 'sm'} 
                mb={errors && errors.last_name ? 'xxs' : 'sm'}
                sx={{ bg:'white', color: 'black' }}/>
            {errors && errors.last_name && 
                <Box fontSize='md'color='red'>{errors.last_name.message}</Box>
            }
            <Label mb='xs' htmlFor='roles'>Role</Label>
            <Select
                id='roles'
                name='roles'
                defaultValue={{ value: 'viewer', label: 'Viewer' }}
                onChange={(e) => handleSelectChange(e, 'role')}
                options={roles} />
            <br/>
            <Label mb='xs' htmlFor='specialists'>Specialist</Label>
            <Select
                defaultValue={{ value: false, label: 'False' }}
                id='specialists'
                name='specialists'
                onChange={(e) => handleSelectChange(e, 'specialist')}
                options={specialists} />
            <Button mt='md' bg={saved.color} sx={{
                borderColor: saved.color,
                pointerEvents: saved.pointerEvents }}>{saved.text}</Button>
        </Box>
    );
};

function mapStateToProps(state) {
    return {
        errors: state.User.errors,
        message: state.User.message,
    };
}

export default connect(mapStateToProps)(EditView)