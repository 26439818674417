import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { Input } from '@rebass/forms/styled-components'
import * as algoliasearch from 'algoliasearch'

const QuickSearchBar = ({ search, source }) => {
    const [results, setResults] = useState({ hits: false, term: '' })
    let timeOutId = null

    const doSearch = ( e ) => {
        clearTimeout(timeOutId)
        const algolia = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
        const index = algolia.initIndex(source)
        const filter = { hitsPerPage: 1000, typoTolerance: 'min' }
        filter.facetFilters = []
        if (source === 'dev_COLLECTION' || source === 'stage_COLLECTION' || source === 'prod_COLLECTION') {
            filter.facetFilters.push('active:true')
        }
        const term = e.target.value
        timeOutId = setTimeout(() => {
            if (term !== '') {
                index.search(term, filter).then(({ hits }) => {
                    setResults({ hits, term })
                })
            } else {
                search({ first: true, filter: []})
            }
        }, 500)
    }

    useEffect(() => {
        if (results.hits.length) {
            const idArray = []
            results.hits.forEach((result) => {
                idArray.push(result.objectID)
            })
            search({ first: true, filter: idArray, term: results.term })
        }
    }, [results])

    return (
        <Box flexGrow='1'>
            <Flex mx='xs' justifyContent="center" sx={{
                position: 'relative',
                zIndex: '999',
            }}>
                <Input
                    id='search'
                    name='search'
                    type='text'
                    placeholder='Quick Search...'
                    variant='searchInput'
                    onChange={ doSearch }
                />
            </Flex>
        </Box>
    )
}

export default QuickSearchBar
