import React from 'react'
import { Flex } from 'rebass/styled-components'
import styled from "styled-components"

const Item = styled(Flex)`
    align-items: center;
    justify-content: flex-start;
    padding: ${props => props.theme.space[1]} 0;
    &.center {
        justify-content: center;
    }
    &.end {
        justify-content: flex-end;
    }
`

const Cell = ({ children, ...rest }) => {
    return (
        <Item {...rest}>
            {children}
        </Item>
    )
}

export default Cell