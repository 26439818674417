import React from 'react'
import { Box  } from 'rebass/styled-components'
import styled from "styled-components"

const Item = styled(Box)`
    border-bottom: 2px solid ${props => props.theme.colors.whiteOff};
    display: grid;
    grid-gap: 1;
    grid-template-columns: ${props => props.template};
    position: relative;
`

const NewThemeItem = styled(Box)`
    display: grid;
    grid-gap: 1;
    grid-template-columns: ${props => props.template};
    position: relative;
`

const Row = ({ children, newTheme = false, ...rest }) => {
    return (
        newTheme?
            <NewThemeItem {...rest}>
                {children}
            </NewThemeItem>
            : <Item {...rest}>
                {children}
            </Item>
    )
}

export default Row