import React from 'react'
import { Router } from '@reach/router'
import { withPrefix } from 'gatsby'
import { UsersSection } from '../system/settings/pageData'
import UsersView from '../views/users/index'
import RegisterView from '../views/users/register'
import EditView from '../views/users/modify'
import Pointer from '../views/pointer'

const PageInfo = {
    parent: UsersSection.section,
    page: 'Add New',
    icon: UsersSection.icon,
}

const UserRoutes = () => {
    return (
        <Router>
            <Pointer PageInfo={{ ...PageInfo, page: 'Add' }} Component={RegisterView} minimumRole={UsersSection.minimumRole} path={withPrefix('/users/add')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'Edit' }} Component={EditView} minimumRole={UsersSection.minimumRole} path={withPrefix('/users/edit/:id')} />
            <Pointer PageInfo={{ ...PageInfo, page: 'All Users' }} Component={UsersView} minimumRole={UsersSection.minimumRole} path={withPrefix('/users')} />
        </Router>
    );
}

export default UserRoutes
