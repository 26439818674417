import React, { useState } from 'react'
import { Box } from 'rebass/styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

const EllipsisControls = ({ children, pr = 3 }) => {
    const [open, setState] = useState(false)
    return (
        <Box onClick={() => setState(!open)} pr={pr}  sx={{
            cursor: 'pointer',
            position: 'relative'
        }}>
            <FontAwesomeIcon icon={faEllipsisV} />
            <Box bg="greyLight" color="greyMedium" width="150px" onMouseLeave={() => setState(false)} sx={{
                borderRadius: 4,
                display: open ? 'block' : 'none',
                zIndex: 1,
                position: 'absolute',
                top: '30px',
                right: 3
            }}>
                { children }
            </Box>
        </Box>
    )
}

export default EllipsisControls