import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { addBulkItem, removeBulkItem } from '../../system/redux/reducers/tables'

const CheckBox = styled(Flex)`
   border: 2px solid ${(props) => props.theme.colors.greyLight};
   border-radius: 4px;
   cursor: pointer;
   height: 18px;
   width: 18px;
`

const TableCheckBox = ({ dispatch, doc, table, checked, onPress, bulkItems }) => {
    const [isChecked, setState] = useState({ all: checked, checked: false })
    useEffect(() => {
        if (typeof checked !== 'undefined' && checked !== isChecked.all) {
            if (checked && !isChecked.checked) {
                const tablesData = JSON.stringify({
                    item: doc,
                    table,
                })
                dispatch(addBulkItem(tablesData))
            }
            if (!checked && isChecked.checked) {
                const tablesData = JSON.stringify({
                    item: doc,
                    table,
                })
                dispatch(removeBulkItem(tablesData))
            }
        }
    }, [checked])

    useEffect(() => {
        if (typeof bulkItems !== 'undefined') {
            setState({ all: checked, checked: bulkItems.includes(doc) })
        } else {
            setState({ all: false, checked: false })
        }
    }, [bulkItems, doc])

    const fireClick = () => {
        if (onPress) {
            setState({ checked: !isChecked.checked })
            return onPress()
        }
        if (typeof bulkItems === 'undefined' || !bulkItems.includes(doc)) {
            const tablesData = JSON.stringify({
                item: doc,
                table,
            })
            dispatch(addBulkItem(tablesData))
        } else {
            const tablesData = JSON.stringify({
                item: doc,
                table,
            })
            dispatch(removeBulkItem(tablesData))
        }
    }

    return (
        <CheckBox onClick={fireClick} alignItems="center" justifyContent="center" fontSize="10px">
            { isChecked.checked && <FontAwesomeIcon icon={faCheck} /> }
        </CheckBox>
    )
}

function mapStateToProps(state, props) {
    return {
        bulkItems: state.Tables.bulk_items[props.table],
    }
}

export default connect(mapStateToProps)(TableCheckBox)
