import React from 'react'
import { Flex } from 'rebass/styled-components'
import styled from 'styled-components'

const ListItem = styled(Flex)`
   border-bottom: 1px solid ${(props) => props.theme.colors.white};
   cursor: pointer;
   padding: 8px ${(props) => props.theme.space[3]};
   font-size: ${(props) => props.theme.fontSizes.sm};
   &:hover {
       background-color: ${(props) => props.theme.colors.greyMedium};
       color: ${(props) => props.theme.colors.white};
   }
`
const EllipsisControlsItems = ({ children, onClick }) => {
    return (
        <ListItem onClick={onClick}>{children}</ListItem>
    )
}

export default EllipsisControlsItems
