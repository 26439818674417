import React from 'react'
import { connect } from 'react-redux';
import { Button, Box, Flex, Heading } from 'rebass/styled-components'
import styled from 'styled-components'
import Loading from '../general/Loading'
import TableTab from '../tabs/TableTab';
import Row from './Row'
// import ActionSelect from '../selects/ActionSelect'
// import FilterSelect from '../selects/FilterSelect'
// import ListItem from '../selects/ListItem'
// import { confirmBoxToggle } from '../../system/redux/reducers/confirmbox'
import QuickSearchBar from '../../components/searchBar/QuickSearchBar'

const TableContainer = styled(Box)`
    border-top: ${(props) => props.newTheme ? 0 : `4px solid ${props.theme.colors.whiteOff}`};
`

const Table = ({ newTheme = false, dispatch, title, ID, contentType, addNew, bulk_items, template, headerRow, actions = true, facetfilter = false, children, fetchMoreData, algoliaIndice, tabs, currentTab, onTabSelect }) => {
    // const areYouSure = () => {
    //     let confirmData = ''
    //     if ( !bulk_items[ID] || !bulk_items[ID].length ) {
    //         confirmData = JSON.stringify({ isOpen: true, id: '', modalType: 'EMPTY_CHECKBOXES', collection: null })
    //     } else {
    //         confirmData = JSON.stringify({ isOpen: true, id: bulk_items[ID], modalType: `BULK_DELETE_${contentType}`, collection: null })
    //     }
    //     dispatch(confirmBoxToggle(confirmData))
    // }

    return (
        <Box variant={newTheme ? null : 'card'} py={newTheme ? null : 'lg'}>
            {
                actions || facetfilter || addNew ?
                    <Flex px="lg" pb="xxl" alignItems="center" justifyContent="space-between">
                        <Heading as="h2" variant="h4" color="greyMedium">{title}</Heading>
                        <Flex alignItems="start" justifyContent="start">
                            <Button onClick={addNew} variant='primarySmall' mr="sm">+ New</Button>
                            {/* { actions ? <ActionSelect>
                                <ListItem label="Delete" onPress={areYouSure} />
                            </ActionSelect> : null}
                            { facetfilter ? 
                                <FilterSelect /> : null
                            } */}
                        </Flex>
                    </Flex>
                    : null
            }
            {
                algoliaIndice ?
                    <Box>
                        <QuickSearchBar search={fetchMoreData} source={algoliaIndice} />
                    </Box>
                    : null
            }
            {
                tabs ?
                    <Flex pb="sm" sx={{
                        borderColor: 'whiteOff',
                        borderStyle: 'solid',
                        borderWidth: '0 0 1px',
                    }}>
                        {
                            Object.entries(tabs).map(([key, tab]) => {
                                return <TableTab key={key} name={key} active={currentTab} onClick={() => onTabSelect(key)}>{tab.title}</TableTab>
                            })
                        }
                    </Flex>
                    : null
            }
            <TableContainer newTheme={newTheme}>
                <Row newTheme={newTheme} key='table_main' template={template} py={newTheme? 3 : 1} fontWeight={newTheme ? 'bold' : 'semibold'} color={newTheme ? 'greyDark' : null} sx={newTheme ? { borderColor: 'greyMedium', borderStyle: 'solid', borderWidth: '1px 0' } : null}>
                    {headerRow}
                </Row>
                {children || <Loading />}
            </TableContainer>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        bulk_items: state.Tables.bulk_items,
    }
}

export default connect(mapStateToProps)(Table)
